.App {
  text-align: center;
  background-color: #0E1B23;
  padding-top: 20px;
  color: white;
  font-family: "Rajdhani", sans-serif;

  
}
body{
  background-color: #0E1B23;
}
td{
  padding: 25px;
}
td{
  border-bottom: 1px solid white;
  
}
tr{
  border-radius: 50%;
  
}
table{
  border: none;
}
.login{
  background-color:#0D4F74 ;
  width: 400px;
  margin: auto;
  padding: 20px;
  
  font-family: "Rajdhani", sans-serif;
  border-radius: 2px;
}
h4{
  font-family: "Rajdhani", sans-serif;
}
.admin-form{
  background-color: #0D4F74;
  width: 600px;
  padding: 30px;
  margin: auto;
}
.login-form{
  width: 350px;
  margin: 20px auto;
  border: none;
  background: #d9d9d9;
  color: #000;
  font-family: "Rajdhani", "sans-serif";
  font-size: 20px;
  font-style: normal;
  display: block;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  border: none;
  outline: none;
}
th{
  position: sticky;
}

.login-btn{
  color: #fff;

  width: 120px;
  height: 50px;
  margin-top: 10px;
  background-color: #0E1B23;

  font-size: 15px;
  font-style: normal;

  font-weight: bolder;
  border: 0;
  z-index: 999;
  outline: 0;
  -webkit-user-select: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-9 {
  appearance: button;
  backface-visibility: hidden;
  background-color:#0E1B23;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}
thead{
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #000;
  padding: 20px;
}

.button-9:disabled {
  cursor: default;
}

.button-9:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}
.admins{
  background-color: #0D4F74;
  padding: 20px;
  width: 300px;
  margin:30px auto;
}